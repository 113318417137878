import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeIndex from '@/components/HomeIndex.vue'

Vue.use(VueRouter)

// 解决导航重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',//页面最初加载的路径，默认为/
    name: 'home',
    component: HomeIndex,//页面最初加载的组件
    redirect: 'homeView',//路由重定位，由于刚开始的路由'/'没有对应的组件显示，所以设置页面加载时显示'/index'首页内容
    children: [//路由的嵌套，实现页面局部刷新
      {
        path: 'homeView',
        name: 'homeView',
        meta: {
          title: "-主页",
        },
        component: () => import('@/views/HomeView.vue')// 在vue中@表示src目录
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/ProfileView.vue')
      },
      {
        path: 'business',
        name: 'business',
        component: () => import('@/views/BusinessView.vue')
      },
      {
        path: 'about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	}
})

export default router

//title
const defaultTitle = "石家庄走走兽网络科技有限公司";
document.title = defaultTitle;
// router.beforeEach((to, from, next) => {
// 	// document.title = to.meta.title ? to.meta.title : defaultTitle;
//   document.title = to.meta.title ? defaultTitle + to.meta.title : defaultTitle;
// 	next();
// });
