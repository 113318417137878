<template>
  <div class="homeIndex">
    <!-- <div class="nav_bg">
      <img src="@/assets/img/bg1.png" alt="" />
    </div> -->
    <div class="head">
      <div class="logo">
        <img src="@/assets/img/logo.png" alt="">
      </div>
      <nav>
        <div :class="{ active: currentIndex === index }" v-for="(item, index) in navArray" :key="index"
          @click="jump(item.path)">
          {{ item.title }}</div>
        <!-- <div :class="currentIndex === index ? 'active' : ''" v-for="(item, index) in navArray" :key="index"
          @click="jump(index, item.path)">
          {{ item.title }}</div> 老写法-->
      </nav>
    </div>
    <router-view />
    <div class="footer_cont">
      <div class="footer_contDe" v-for="(item, index) in footerCoentArr" :key="index">
        <img :src=item.img alt="">
        <span>{{ item.title }}</span>
        <p>{{ item.content }}</p>
      </div>
    </div>
    <div class="footer_warp_btm">
      <div class="foot_nav_list">
        <div class="footer_nav" v-for="(item, index) in navArray" :key="index" @click="jump(item.path)">
          {{ item.title }}</div>
      </div>
      <div class="tips">
        Copyright
        ©&ensp;&ensp;石家庄走走兽网络科技有限公司&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;冀ICP备2024050284号
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeIndex',
  data() {
    return {
      currentIndex: 0,
      navArray: [
        {
          title: '公司主页',
          path: '/homeView'
        },
        {
          title: '公司简介',
          path: '/profile'
        },
        {
          title: '项目案例',
          path: '/business'
        },
        {
          title: '联系我们',
          path: '/about'
        }
      ],
      footerCoentArr: [
        {
          img: require('@/assets/img/aixin.png'),
          title: '诚信',
          content: '忠勇诚信是中华民族的传统美德，更是一个古老的道德命题。'
        },
        {
          img: require('@/assets/img/chuangxin.png'),
          title: '创新',
          content: '作为企业，必须聆听了解客户的需求，做到超越客户的期望。'
        },
        {
          img: require('@/assets/img/pinzhi.png'),
          title: '品质',
          content: '"品质铸就辉煌，品质就是价值和尊严" 这是我们的企业文化。'
        },
        {
          img: require('@/assets/img/caifu.png'),
          title: '财富',
          content: '具有价值的东西就称之为财富，包括自然财富、物质财富和精神财富。'
        },
      ]
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        // console.log(route)
        // console.log(this.$router)
        let routes = this.$router.options.routes[0].children
        for (let index = 0; index < routes.length; index++) {
          if (route.name == routes[index].name) {
            return this.currentIndex = index
          }
          // if (route.name == 'reportContent' || route.name == 'reportDetails') {
          //   if (routes[index].name == 'report') {
          //     return this.currentIndex = index
          //   }
          // }
        }
      },
      immediate: true
    }
  },
  methods: {
    jump(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style>
.homeIndex {
  overflow: auto;
}

.homeIndex::-webkit-scrollbar {
  height: 0 !important;
}


.head {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 1200px;
  margin: 10px auto;
}

.logo img {
  width: 245px;
}

nav {
  padding: 30px;
}

nav div {
  font-weight: bold;
  /* color: #2c3e50; */
  /* text-decoration: none; */
  /* width: 120px; */
  /* border-right: 1px solid #efefef; */
  text-align: center;
  float: left;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
  /* position: relative; */
  font-size: 16px;
  cursor: pointer;
  margin-left: 30px;
}

nav div.active {
  color: #dd0000;
  border-bottom: 2px solid #dd0000;
}

nav div:hover {
  color: #dd0000;
}


.footer_cont {
  display: flex;
  align-items: center;
  background: linear-gradient(to top, transparent, #6a94bf), url("@/assets/img/fazhan_bg.png");
  /* width: 1200px;
  min-width: 1200px; */
  margin: 10px auto;
  height: 400px;
  justify-content: space-around;
}

.footer_contDe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_contDe img {
  width: 75px;
  height: 75px;
  padding-bottom: 35px;
}

.footer_contDe span {
  color: #1E50AE;
  font-size: 18px;
  font-weight: bold;
}

.footer_contDe p {
  width: 200px;
  padding-top: 10px;
  line-height: 30px;
}

.footer_warp_btm {
  margin: 0 auto;
  margin-top: -10px;
  background: #17213d;
}

.foot_nav_list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.foot_nav_list div {
  margin: 30px 0px;
  padding: 0px 30px;
  color: #ffffff;
  border-right: 2px solid #ffffff;
  cursor: pointer;
}

.foot_nav_list div:hover {
  color: #dd0000;
}

.foot_nav_list div:last-child {
  border-right: 0px solid #ffffff;
}

.tips {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 48px;
  text-align: center;
  border-top: 1px solid #444444;
}
</style>
